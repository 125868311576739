const contributors = [
  { amount: 15 },
  { amount: 50 },
  { amount: 50 },
  { amount: 25 },
  { amount: 15 },
  { amount: 20 },
  { amount: 10 },
  { amount: 10 },
  { amount: 20 },
  { amount: 12 },
  { amount: 15 },
  { amount: 10 },
  { amount: 25 },
  { amount: 10 },
  { amount: 10 },
  { amount: 10 },
  { amount: 13 },
  { amount: 10 },
  { amount: 25 },
  { amount: 15 },
  { amount: 50 },
  { amount: 10 },
  { amount: 15 },
  { amount: 15 },
  { amount: 50 },
  { amount: 15 },
  { amount: 25 },
  { amount: 70 },
  { amount: 15 },
  { amount: 15 },
  { amount: 10 },
  { amount: 15 },
  { amount: 10 },
  { amount: 10 },
  { amount: 200 },
  { amount: 15 },
];

export default contributors;
