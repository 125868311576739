import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import React from "react";
import ContributorList from "./ContributorList";

function App() {
  return (
    <div style={{ width: "100%", height: "100%", background: "#ffffff" }}>
      <Parallax pages={5}>
        <>
          <ParallaxLayer offset={0} speed={0.3}>
            <div
              className="flex flex-col justify-center items-center"
              style={{
                position: "absolute",
                top: "35%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <img alt="adessoLogo" src={"adessoLogo.png"} />
              <p className="text-3xl font-bold">Ticino Pizza Lover Club</p>
            </div>
          </ParallaxLayer>
          <ParallaxLayer offset={0} speed={0.2}>
            <img
              className="w-[200px]"
              alt="pizza_slide"
              src={"pizza2.png"}
              style={{
                position: "absolute",
                top: "70%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          </ParallaxLayer>
        </>

        <>
          <ParallaxLayer offset={1} sticky={{ start: 1, end: 3 }} speed={0.2}>
            <img
              className="giuseppe w-[300px]"
              alt="giuseppe"
              src={"giuseppe.jpeg"}
              style={{
                position: "absolute",
                top: "50%",
                left: "30%",
                transform: "translate(-50%, -50%)",
              }}
            />
          </ParallaxLayer>
          <ParallaxLayer offset={1} speed={0.5}>
            <div
              className="flex flex-col w-[500px] justify-center items-center"
              style={{
                position: "absolute",
                top: "45%",
                left: "60%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <p className="w-1/2 text-3xl font-bold">One man..</p>
              <p className="w-1/2 text-3xl font-bold">One Passion..</p>
              <p className="w-1/2 text-3xl font-bold">
                One <span className="text-blue-500">Pizza.</span>
              </p>
            </div>
          </ParallaxLayer>
          <ParallaxLayer offset={2} speed={0.5}>
            <div
              className="flex flex-col w-[500px] justify-center items-center gap-2"
              style={{
                position: "absolute",
                top: "45%",
                left: "60%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <p className="w-1/2 text-3xl font-bold">Every Thursday</p>
              <p className="w-1/2 text-3xl font-bold">Only in Ticino</p>
            </div>
          </ParallaxLayer>
          <ParallaxLayer offset={3} speed={0.5}>
            <div
              className="flex flex-col w-[500px] justify-center items-center gap-8"
              style={{
                position: "absolute",
                top: "45%",
                left: "60%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <p className="w-1/2 text-3xl font-bold">
                Get a copy of the real{" "}
                <span className="text-blue-500">Recipe.</span>
              </p>
              <a href={require("./PizzaRecipe.txt")} download="PizzaRecipe">
                <button className="bg-blue-500 w-full hover:bg-blue-700 text-white w-1/2 text-2xl font-bold py-2 p-4 rounded-full">
                  Download
                </button>
              </a>
            </div>
          </ParallaxLayer>
        </>

        <>
          <ParallaxLayer offset={4} speed={1}>
            <div
              className="flex flex-col justify-start w-[400px] gap-2"
              style={{
                position: "absolute",
                top: "45%",
                left: "45%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <ContributorList />
            </div>
          </ParallaxLayer>
          <ParallaxLayer offset={4} speed={0.4}>
            <div
              className="flex flex-col justify-start w-[400px] gap-2"
              style={{
                position: "absolute",
                top: "45%",
                left: "60%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <p className="w-1/2 text-3xl font-bold">Donations</p>
              <h1>TWINT / YUH: +41 79 685 38 69</h1>
              <h1>
                BONIFICO: Banca: Swissquote Bank | Beneficiario: Lobianco Mattia
                | IBAN: CH4308781000210847000 | SWIFT: SWQBCHZZXXX
              </h1>
              <h1>Paypal: mattia.lobianco.95@gmail.com</h1>
            </div>
          </ParallaxLayer>
        </>
      </Parallax>
    </div>
  );
}

export default App;
