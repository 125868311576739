import React from "react";
import contributors from "./Donation";

const ContributorList = () => {
  // const sortedContributors = contributors.sort((a, b) => b.amount - a.amount);
  const totalAmount = contributors.reduce(
    (sum, contributor) => sum + contributor.amount,
    0
  );

  return (
    <div>
      <progress max="1000" value={totalAmount} />
      <p>Collected {totalAmount} CHF</p>
    </div>
  );
};

export default ContributorList;
